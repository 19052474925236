import EventEmitter from 'events';
import Raven from 'raven-js';

import * as net from '../net';

class UserStore extends EventEmitter {
  constructor() {
    super();

    this.user = null;
    this.customer = null;
    this.site_setup = null;
    this.waiting_for_user_response = true;
  }

  get_user() {
    return this.user;
  }

  set_user(user, customer, site_setup) {
    this.user = user;

    if (customer) {
      this.customer = customer;
    }

    if (site_setup) {
      this.site_setup = site_setup;
    }

    this.waiting_for_user_response = false;

    this.emit_change();
    return this.user;
  }

  get_waiting_for_response() {
    return this.waiting_for_user_response;
  }

  set_customer(customer) {
    this.customer = customer;
    this.emit_change();
    return this.customer;
  }

  get_customer() {
    return this.customer;
  }

  emit_change() {
    this.emit('change');
  }

  get_site_setup() {
    return this.site_setup;
  }
}

const user_store = new UserStore();

const get_current_user = async() => {
  const {user: current_user, customer, site_setup} = await net.get({url: '/auth/current-user'})
  user_store.set_user(current_user, customer, site_setup);

  if (!current_user) {
    return null;
  }
  const {email, id: user_id, first_name, last_name} = current_user;

  let customer_id, customer_name;
  if (customer) {
    ({id: customer_id, name: customer_name} = customer);
  }

  /*window.analytics.identify(user_id, {
    email,
    first_name,
    last_name,
    customer_id,
    customer_name
  });*/

  Raven.setUserContext({
    email,
    id: user_id,
  })
}

get_current_user();

export default user_store;
