import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import reactUpdate from 'react-addons-update';

import CartStore from './CartStore';
import CartDAO from './CartDAO';
import UserStore from '../auth/UserStore';
import ItemStore from '../product/ItemStore';
import {ModifyQuantityButtonGroup, ItemCartQuantityWarnings} from './AddToCartButton'
import {
  OrderItemsTable,
  ITEM_VOLUME_COLUMN,
  ITEM_CARTON_COLUMN,
  ITEM_WEIGHT_COLUMN
} from '../orders/OrderItemsTable';
import {OrderNavProgress} from '../orders/OrderNavProgress';
import {AddressFields, EMPTY_SHIPPING_ADDRESS} from '../components/AddressFields';
import util from '../util';
import Price from '../components/Price';
import Modal from '../components/Modal';

const get_customer_price = (customer, user, item) => {
  const {price_tradeshow_special} = item;
  if (price_tradeshow_special) {
    return price_tradeshow_special;
  }

  const {current_price_level} = user;
  if (current_price_level) {
    return item[current_price_level];
  }

  let price_level = 'price_msrp';
  if (customer) {
    price_level = util.PRICE_GROUP_TO_PRICE_LEVEL[customer.price_group];
  }

  if (item['own_price'] && item['own_price'] > 0 && item[price_level] > item['own_price'])
    return item['own_price'];

  return item[price_level];
}

const CartItemQuantityInput = ({quantity, items_per_carton, sku}) => (
  <div className="text-center">
    <ModifyQuantityButtonGroup
      items_per_carton={items_per_carton}
      quantity={quantity}
      minimum_quantity={0}
      update_quantity={
        (quantity) => CartStore.update_cart_quantity(sku, quantity)}
    />

    <ItemCartQuantityWarnings
      item={ItemStore.get_item(sku)}
      quantity_in_cart={quantity}
    />

    {quantity === 0 && (
      <div className="btn btn-small btn-link" onClick={() => CartStore.remove_from_cart(sku)}>
        Remove from cart
      </div>
    )}
  </div>
);

CartItemQuantityInput.propTypes = {
  sku: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  items_per_carton: PropTypes.number.isRequired,
};

class AddSkuToCartForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      error: null,
      text: '',
    }
  }

  add_sku_to_cart = async(e) => {
    e.preventDefault();

    const {text} = this.state;

    this.setState({is_loading: true, error: null});

    try {
      const request_data = {sku_text: text}
      const {items, sku_to_quantity_added_to_cart} = await CartDAO.add_to_cart(request_data);
      ItemStore.add_items(items);

      items.forEach((item) => {
        CartStore.add_to_cart(item.sku, sku_to_quantity_added_to_cart[item.sku]);
      })
    } catch (e) {
      let error = 'Problem adding to cart...';

      const {responseJSON} = e;

      if (responseJSON) {
        const {error_message} = responseJSON;
        error = error_message || error;
      }

      this.setState({error});
    }

    this.setState({is_loading: false, text: ''});
  }

  render() {
    const {text, error, is_loading} = this.state;

    return (
      <div>
        <form className="form-inline" onSubmit={this.add_sku_to_cart}>
          <div>
            <input
              type="text"
              className="form-control mr-2"
              placeholder="Enter SKU to add"
              value={text}
              onChange={(e) => this.setState({text: e.target.value})}
            />
          </div>

          <button disabled={is_loading} type="submit" className="btn btn-success">Add to Cart</button>
        </form>

        {error && (
          <div className="text-danger">{error}</div>
        )}
      </div>
    )
  }
}

class CartOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cart_sku_to_quantity: CartStore.get_cart(),
      customer: UserStore.get_customer(),
      user: UserStore.get_user(),
      order_notes: '',
      is_loading: false,
      error: null,
      customer_purchase_order_id: '',
      show_add_to_cart_form: false,
      isOpen: false,
      new_shipping_address: EMPTY_SHIPPING_ADDRESS,
    };
  }

  componentDidMount() {
    CartStore.on('change', this.setCartState);
    UserStore.on('change', this.setUserState);
  }

  componentWillUnmount() {
    CartStore.removeListener('change', this.setCartState);
    UserStore.removeListener('change', this.setUserState);
  }

  setCartState = () => {
    this.setState({
      cart_sku_to_quantity: CartStore.get_cart()
    });
  }

  setUserState = () => {
    this.setState({
      customer: UserStore.get_customer(),
      user: UserStore.get_user(),
    });
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  get_subtotal() {
    const {customer, user, cart_sku_to_quantity} = this.state;

    return cart_sku_to_quantity.reduce((curr_sum, quantity, sku) => {
      const item = ItemStore.get_item(sku);
      const price = quantity * get_customer_price(customer, user, item)
      return curr_sum + price;
    }, 0);
  }

  submit = (e) => {
    e.preventDefault();
    this.toggleModal();
  }

  place_order = async(e) => {
    e.preventDefault();
    this.setState({is_loading: true});

    const {order_notes: notes_user, customer, user, customer_purchase_order_id, new_shipping_address} = this.state;

    CartStore.clean_out_zero_quantity_items();
    const sku_to_quantity = CartStore.get_cart();

    const price_level = user.current_price_level || util.PRICE_GROUP_TO_PRICE_LEVEL[customer.price_group];

    const sku_to_cost = _.fromPairs(
      _.map(sku_to_quantity.toObject(), (quantity, sku) =>
        [sku, get_customer_price(customer, user, ItemStore.get_item(sku))]));
    const subtotal = this.get_subtotal();

    try {
      const {order} = await CartDAO.createOrderFromCart({
        sku_to_quantity,
        notes_user,
        subtotal,
        sku_to_cost,
        price_level,
        customer_purchase_order_id,
        new_shipping_address,
      });
      CartStore.reset_cart();
      this.props.history.push(`/orders/${order.id}/shipping`);

    } catch (e) {
      this.setState({is_loading: false, error: 'Problem creating cart, try refreshing.'});
    }
  }

  render() {
    const {
      user, customer, cart_sku_to_quantity, order_notes, is_loading, error, customer_purchase_order_id,
      show_add_to_cart_form, new_shipping_address
    } = this.state;

    const submit_disabled = is_loading || cart_sku_to_quantity.isEmpty();
    const sorted_skus = _.sortBy(_.map(cart_sku_to_quantity.toObject(), (quantity, sku) => sku))

    return (
      <div className='container'>
        <Helmet>
          <title>{'Cart - Euro Style'}</title>
          <meta name="description" content={
            'Easily stock your retail store or showroom using Euro Style products.'}/>
        </Helmet>

        <div className='row'>
          <div className='col-12 mx-auto'>
            <OrderNavProgress active_section="cart"/>

            <h1 className="mb-4">Your <span className='bold'>Cart</span></h1>

            <OrderItemsTable
              skus={sorted_skus}
              extra_columns={[
                {
                  key: 'quantity',
                  header_text: 'Quantity',
                  right_align: true,
                  to_value: (item, row_context) => row_context.quantity,
                  render: (item, value) => (
                    <CartItemQuantityInput
                      quantity={value} sku={item.sku} items_per_carton={item.items_per_carton}/>
                  ),
                },
                {
                  key: 'unit_cost',
                  header_text: 'Unit Cost',
                  right_align: true,
                  to_value: (item, row_context) => get_customer_price(customer, user, item),
                  render: (item, value) => <Price price={value}/>,
                },
                {
                  key: 'cost',
                  header_text: 'Cost',
                  right_align: true,
                  show_subtotal: true,
                  to_value: (item, row_context) => (
                    get_customer_price(customer, user, item) * row_context.quantity
                  ),
                  render: (item, value) => <Price price={value}/>,
                },
                ITEM_VOLUME_COLUMN,
                ITEM_WEIGHT_COLUMN,
                ITEM_CARTON_COLUMN,
              ]}
              get_row_context={(item) => ({quantity: cart_sku_to_quantity.get(item.sku)})}/>

            <div className="mb-4 d-flex flex-row-reverse">
              { (() => {
                  if (customer && customer.account_status === "Active" && customer.category !== 'SPEC') { 
                    if (show_add_to_cart_form) { 
                      return (<AddSkuToCartForm/>)
                    } else {
                      return (
                        <div
                          className="btn btn-sm btn-link"
                          onClick={() => this.setState({show_add_to_cart_form: true})}
                        >
                          Add a sku to cart
                        </div>
                      )
                    }
                  }
                }
              )()}

              <Link
                className="btn btn-sm btn-link"
                to="/presentations/create"
              >
                Create presentation
              </Link>

              <div
                className="btn btn-sm btn-link"
                onClick={() => {
                  if (window.confirm('Do you want to clear the cart?')) {
                    CartStore.reset_cart()
                  }
                }}
              >
                Clear the cart
              </div>
            </div>

            <div className='col-12 col-md-8 mx-auto'>
              <p className='mb-4'>
                After submitting the order, a customer service person will be in touch with you to
                confirm your order.
              </p>

              <p className='mb-4'>
                If you need a freight quote prior to placing the order, please, email your account
                executive or <a href="mailto:cs@euro.style">cs@euro.style</a>
              </p>

              <div className='row'>
                <form onSubmit={this.submit} className='col-12'>
                  <div className="form-group">
                    <label className='font-weight-bold'>PO Number</label>
                    <input
                      maxLength={30}
                      className='form-control'
                      type="text"
                      value={customer_purchase_order_id}
                      onChange={(e) => this.setState({customer_purchase_order_id: e.target.value})}/>
                  </div>

                  <div className='form-group'>
                    <label className='font-weight-bold'>Order Notes</label>
                    <textarea
                      className='form-control'
                      value={order_notes}
                      onChange={(e) => this.setState({order_notes: e.target.value})}/>
                  </div>

                  <div className='form-group'>
                    <h4>Shipping Information:</h4>
                  </div>

                  <AddressFields
                    update_address={(new_obj) => {
                      const new_shipping_address = reactUpdate(this.state.new_shipping_address, {$merge: new_obj});
                      this.setState({new_shipping_address});
                    }}
                    {...new_shipping_address}
                  />
                  {error && (
                    <div className="alert alert-danger">{error}</div>
                  )}

                  <button
                    className='btn btn-success float-right'
                    disabled={submit_disabled}
                  >
                    Place Order
                  </button>
                  <Modal show={this.state.isOpen} onClose={this.toggleModal} onRun={this.place_order} title="Place Order">
                    Do you want to place the order?
                  </Modal>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export {CartOverview};
