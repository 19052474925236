import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import {QrReader} from 'react-qr-reader'
import _ from 'lodash';
import {Link} from 'react-router-dom';

import CartStore from '../cart/CartStore';
import ItemStore from '../product/ItemStore';
import dao from '../dao';
import InventoryDAO from './InventoryDAO';
import {GetUserWrapper} from '../comments/CommentsViews';

import {CartDataWrapper} from '../cart/CartComponents';
import {OrderItemsTable} from '../orders/OrderItemsTable';

const PRODUCT_TAG_QR_CODE_REGEXP = new RegExp('https://euro.style/p/(.*)\\?show_id=(.*)')

const parse_product_tag_qr_code_data = (data) => {
  if (!data) {
    return null;
  }

  const match = PRODUCT_TAG_QR_CODE_REGEXP.exec(data);

  if (!match) {
    return null;
  }

  if (match.length !== 3) {
    return null;
  }

  const [sku, show_id] = match;

  return {
    sku,
    show_id,
    url: match,
  }
}


const SCAN_STATE_NEW_SCAN = 'green';
const SCAN_STATE_EXISTING_SCAN = 'yellow';

const INVENTORY_SCAN_STATE_PROCESSING_SCAN = 'orange';
const INVENTORY_SCAN_STATE_RECORDED_SCAN = 'blue';


class ProductScanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  parse_scan_for_product_info = (data) => {
    if (!data) {
      return;
    }

    const parsed_data = parse_product_tag_qr_code_data(data)

    if (!parsed_data) {
      return;
    }

    this.props.on_product_scan(parsed_data);
  }

  render() {
    const {disabled} = this.props;

    return (
      <QrReader
        delay={disabled ? false : 1500}
        onError={(error) => console.error(error)}
        onScan={this.parse_scan_for_product_info}
        constraints={{ facingMode: 'environment' }}
        style={{width: '100%'}}
      />
    )
  }

}

ProductScanner.propTypes = {
  on_product_scan: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

class ScanAndGoToProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    const {user} = this.props;

    return (
      <div>
        <h2>Product Scanner</h2>

        <p>
          On scanning the QR code, this will open the page for that product.
        </p>

        <p>
          This scanner requires Safari on iPhones and Chrome on Androids.
        </p>

        {user && user.is_active && (
          <p>
            To add items to your cart, <Link to="/scanner-for-cart">click here</Link>.
          </p>
        )}

        <ProductScanner
          on_product_scan={({sku, show_id}) => {
            /*window.analytics.track('Scanned Product', {
              sku,
              show_id,
            });*/

            const product_path = `/p/${sku}?show_id=${show_id}`
            this.props.history.push(product_path)
          }}
        />
      </div>
    )
  }
}

ScanAndGoToProduct.propTypes = {
  history: PropTypes.object.isRequired,
}

class ScanAndAddToCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scan_state: null,
    }
  }

  add_product_to_cart = async({sku, show_id}) => {
    /*window.analytics.track('Scanned Product for Cart', {
      sku,
      show_id,
    });*/

    const {cart_sku_to_quantity} = this.props;

    let scan_state;
    if (cart_sku_to_quantity.has(sku)) {
      scan_state = SCAN_STATE_EXISTING_SCAN;
    } else {
      scan_state = SCAN_STATE_NEW_SCAN;
    }

    this.setState({scan_state});

    if (scan_state) {
      setTimeout(() => {
        this.setState({scan_state: null});
      }, 500);
    }

    const item = await dao.getItem(sku);
    ItemStore.add_item(item);

    const quantity = item.items_per_carton

    if (item.item_type === 'KIT') {
      const {components} = await dao.getItemComponents(sku);
      ItemStore.add_items(components);

      components.forEach((component) => {
        CartStore.add_to_cart(component.sku, quantity);
      });
    } else {
      CartStore.add_to_cart(sku, quantity);
    }
  }

  render() {
    const {cart_sku_to_quantity} = this.props;
    const {scan_state} = this.state;

    const sorted_skus = _.sortBy(_.map(cart_sku_to_quantity.toObject(), (quantity, sku) => sku))

    return (
      <div>
        <h2>Scan for Cart</h2>

        <div style={{
          border: `10px solid ${scan_state || 'white'}`,
          padding: 10,
        }}>
          <ProductScanner
            on_product_scan={this.add_product_to_cart}
          />
        </div>

        <OrderItemsTable
          skus={sorted_skus}
        />

        <div className="my-4 d-flex justify-content-around">
          <Link
            className="btn btn-success"
            to="/cart"
          >
            View Cart
          </Link>

          <Link
            className="btn btn-info"
            to="/presentations/create"
          >
            Create Presentation
          </Link>
        </div>
      </div>
    )
  }
}

ScanAndAddToCart.propTypes = {
  cart_sku_to_quantity: PropTypes.object.isRequired,
}

class ScanAndAddToInventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scan_state: null,
      scanner_disabled: false,

      message: null,
      message_type: null,

      inventory_item_count: null,
    }
  }

  add_product_to_inventory = async({sku, show_id}) => {
    this.setState({
      message: null,
      message_type: null,
      scan_state: INVENTORY_SCAN_STATE_PROCESSING_SCAN,
      scanner_disabled: true,
    });

    const {message, message_type, inventory_item_count} = await InventoryDAO.add_item_to_inventory({
      sku,
      show_id
    });

    this.setState({
      message,
      message_type,
      scan_state: INVENTORY_SCAN_STATE_RECORDED_SCAN,
      scanner_disabled: false,
      inventory_item_count,
    });

    setTimeout(() => {
      this.setState({
        scan_state: null
      });
    }, 750);
  }

  render() {
    const {scan_state, scanner_disabled, message, message_type, inventory_item_count} = this.state;

    return (
      <div>
        <h2>Scan for Inventory</h2>

        <div style={{
          border: `10px solid ${scan_state || 'white'}`,
          padding: 10,
        }}>
          <ProductScanner
            on_product_scan={this.add_product_to_inventory}
            disabled={scanner_disabled}
          />
        </div>

        {message && (
          <div className={classNames("my-2 alert", `alert-${message_type}`)}>
            {message}
          </div>
        )}

        <div>
          Scanned so far: <strong>{inventory_item_count}</strong>
        </div>
      </div>
    )
  }
}

const CartScannerPage = ({history}) => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <CartDataWrapper
          render={({cart_sku_to_quantity}) => (
            <ScanAndAddToCart
              cart_sku_to_quantity={cart_sku_to_quantity}
            />
          )}
        />
      </div>
    </div>
  </div>
)

const InventoryScannerPage = ({history}) => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <GetUserWrapper
          render={({user}) => (
            <ScanAndAddToInventory
              user={user}
            />
          )}
        />
      </div>
    </div>
  </div>
)

const ProductScannerPage = ({history}) => (
  <div className="container">
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <GetUserWrapper
          render={({user}) => (
            <div>
              <ScanAndGoToProduct
                history={history}
                user={user}
              />

              {user && user.is_employee && (
                <div className="my-5">
                  <Link to="/scanner-for-inventory">Scan Inventory</Link>
                </div>
              )}
            </div>
          )}
        />
      </div>
    </div>
  </div>
)

export {ProductScannerPage, CartScannerPage, InventoryScannerPage};
